import { Answers, Question, SurveyConfig } from '../../contexts/survey'
import dynamic from 'next/dynamic'
import assets from './assets'
import {
    validateHeight,
    validateTargetWeight,
    validateWeight,
    validateAge,
    validateName,
    validateEatingWindow,
} from '../../utils/validation'
import BodyHeightStep from './BodyHeightStep'
import AgeStep from './AgeStep'
import { SurveyExperiments, SurveyTheme } from '@contexts/survey/survey'
import LottieTextStep from './LottieTextStep'

const DownloadStep = dynamic(() => import('./DownloadStep'))
const PlanReadyStep = dynamic(() => import('./PlanReadyStep/PlanReadyStep'))
const RegistrationStep = dynamic(() => import('./RegistrationStep'))
const YourCurrentStateStep = dynamic(() => import('./v2/YourCurrentStateStep'))
const YourTargetStateStep = dynamic(() => import('./v2/YourTargetStateStep'))
const LosingWeightStep = dynamic(() => import('./v2/LosingWeightStep'))
const PreferredNameStep = dynamic(() => import('./v2/PreferredName'))
const TestimonialsStep = dynamic(() => import('./v2/TestimonialsStep'))
const PersonalSummary = dynamic(() => import('./v2/PersonalSummary'))
const InterstitialStep = dynamic(() => import('./v2/InterstitialStep'))
const FoodScanTextStep = dynamic(() => import('./v2/FoodScanTextStep'))
const TrackerSuccessStep = dynamic(() => import('./v2/TrackerSuccessStep'))
const TechnologiesUsedStep = dynamic(() => import('./v2/TechnologiesUsedStep'))
const ComparisonStep = dynamic(() => import('./v2/ComparisonStep'))
const FoodChoiceStep = dynamic(() => import('./v2/FoodChoiceStep'))
const CalorieCuttingStep = dynamic(() => import('./v2/CalorieCuttingStep'))
const EatingWindowStep = dynamic(() => import('./v2/EatingWindowStep'))
const SpecialOccasionDate = dynamic(() => import('./v2/SpecialOccasionDate'))
const StorytellingMealStep = dynamic(() => import('./v2/StorytellingMealStep'))
const NutrionalSummary = dynamic(() => import('./v2/NutrionalSummary'))
const StorytellingWeightLoss = dynamic(() => import('./v2/StorytellingWeightLoss'))
const StatementStep = dynamic(() => import('./v2/StatementStep'))
const MotivationQuestion = dynamic(() => import('./v2/MotivationQuestion'))
const ShedFatStep = dynamic(() => import('./v2/ShedFatStep'))
const Reviews = dynamic(() => import('./v2/Reviews'))
const PreparePlanStep = dynamic(() => import('./v2/PreparePlanStep'))
const SignUpInsurance = dynamic(() => import('../insurance/InvoiceSignUp'))
const InsurancePaywall = dynamic(() => import('../insurance/InsurancePaywall'))
const LandingPage = dynamic(() => import('./LandingPage'))

const isNumeric = (val: string): boolean => {
    return !isNaN(Number(val))
}

const getSurveyConfigV2 = (
    theme: SurveyTheme,
    funnel: string,
    experiements: SurveyExperiments,
    answers: Answers,
): SurveyConfig => {
    return {
        milestones: [0, 6, 12, 24, 45, 63],
        questions: [
            /*
            {
                name: 'landing',
                type: 'custom',
                path: 'landing',
                isRequired: true,
                hideButtonBar: true,
                hideHeader: true,
                hideStepper: true,
                component: <LandingPage />,
                shouldRender: (currentAnswers: Answers): boolean =>
                    funnel === 'no_loader' && currentAnswers.locale === 'de',
            },
            */
            ...[
                {
                    name: 'goal',
                    type: 'select',
                    path: 'your_goal',
                    isRequired: true,
                    multiple: true,
                    imageOptions: [
                        {
                            label: 'lose_weight',
                            image: assets.goal_v2.lose_weight,
                        },
                        {
                            label: 'boost_health',
                            image: assets.goal_v2.boost_health,
                        },
                        {
                            label: 'healthy_eating',
                            image: assets.goal_v2.healthy_eating,
                        },
                        {
                            label: 'reduce_stress',
                            image: assets.goal_v2.reduce_stress,
                        },
                        {
                            label: 'live_longer',
                            image: assets.goal_v2.live_longer,
                        },
                    ],
                } as Question,
            ]
                .sort((a, b) => (a.name === experiements.firstQuestion ? -1 : 1))
                .filter(e => e),
            {
                name: 'about_diet',
                type: 'custom',
                path: 'regular_diet_vs_fastic',
                buttonNextTitle: 'common:controls.got_it',
                component: <LottieTextStep animation={assets.animations.fastic_vs_diet_chart} />,
            },
            {
                name: 'gender',
                type: 'select',
                path: 'gender',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'female', image: assets.gender_v2.female },
                    { label: 'male', image: assets.gender_v2.male },
                    { label: 'divers', image: assets.gender_v2.divers },
                ],
            } as Question,
            {
                name: 'age',
                type: 'custom',
                path: 'age',
                isRequired: true,
                component: <AgeStep />,
                //choices: ['18-29', '30-39', '40-49', '50-59', '60-69', '70+'],
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['age'] && !validateAge(answers['age'] as number)
                },
            },
            {
                name: 'body_height',
                type: 'custom',
                path: 'body_height',
                isRequired: true,
                component: <BodyHeightStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['height'] && !validateHeight(answers['height'] as number)
                },
            },
            {
                name: 'current_state',
                type: 'custom',
                path: 'your_current_state',
                isRequired: true,
                component: <YourCurrentStateStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['weight'] && !validateWeight(answers['weight'] as number)
                },
            },
            {
                name: 'target_state',
                type: 'custom',
                path: 'your_target_state',
                isRequired: true,
                component: <YourTargetStateStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return (
                        answers['target_weight'] &&
                        !validateTargetWeight(answers['target_weight'] as number, answers['weight'] as number)
                    )
                },
            },
            {
                name: 'losing_weight',
                type: 'custom',
                path: 'losing_weight_with_fastic',
                buttonNextTitle: 'common:controls.got_it',
                component: <LosingWeightStep animation={assets.animations.fasty_perfect_body} />,
            },
            /* Omit because of dropoff rate on this step
            {
                name: 'prefered_name',
                type: 'custom',
                path: 'prefered_name',
                isRequired: true,
                component: <PreferredNameStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    return answers['prefered_name'] && validateName(answers['prefered_name'] as string) === null
                },
            },
            */
            {
                name: 'activity',
                type: 'select',
                path: 'activity_level',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'not_active', image: assets.activity.scale0 },
                    { label: 'mid_active', image: assets.activity.scale1 },
                    { label: 'very_active', image: assets.activity.scale2 },
                    { label: 'max_active', image: assets.activity.scale3 },
                ],
            },
            {
                name: 'work_schedule',
                type: 'select',
                path: 'work_schedule',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'flexible', image: assets.work_v2.flexible },
                    { label: 'nine_to_five', image: assets.work_v2.nine_to_five },
                    { label: 'shift_work', image: assets.work_v2.shift_work },
                    { label: 'strict_schedule', image: assets.work_v2.strict_schedule },
                    { label: 'unemployed', image: assets.work_v2.unemployed },
                    { label: 'seasonal', image: assets.work_v2.seasonal },
                ],
            },
            {
                name: 'weight_loss_familiarity',
                type: 'select',
                path: 'weight_loss_familiarity',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'beginner', image: assets.activity.scale1 },
                    { label: 'intermediate', image: assets.activity.scale2 },
                    { label: 'master', image: assets.activity.scale3 },
                ],
            },
            {
                name: 'testimonials',
                type: 'custom',
                path: 'testimonials',
                hideBackHeader: true,
                component: <TestimonialsStep />,
            },
            {
                name: 'tried_fasting',
                type: 'select',
                path: 'tried_intermittent_fasting',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'yes', image: assets.fasting.positive },
                    { label: 'yes_but_not_interested', image: assets.fasting.neutral },
                    { label: 'no_but_interested', image: assets.fasting.interested },
                    { label: 'not_interested', image: assets.fasting.negative },
                ],
            },
            /* Omit because of dropoff rate on this step
            {
                name: 'pregnancy',
                type: 'select',
                path: 'pregnancy_status',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'not_pregnant', image: assets.pregnancy.not_pregnant },
                    { label: 'pregnant', image: assets.pregnancy.pregnant },
                    { label: 'breastfeeding', image: assets.pregnancy.breastfeeding },
                    { label: 'privat', image: assets.pregnancy.privat },
                ],
                shouldRender: (currentAnswers: Answers) =>
                    currentAnswers.gender && currentAnswers.gender[0] === 'female',
            } as Question,
            */
            {
                name: 'medical_conditions',
                type: 'select',
                path: 'any_medical_conditions',
                isRequired: true,
                multiple: true,
                imageOptions: [
                    { label: 'none', image: assets.medical_conditions.none },
                    { label: 'hypertension', image: assets.medical_conditions.hypertension },
                    { label: 'high_cholesterol', image: assets.medical_conditions.high_cholesterol },
                    { label: 'obesity', image: assets.medical_conditions.obesity },
                    { label: 'diabetes', image: assets.medical_conditions.diabetes },
                    { label: 'heart_disease', image: assets.medical_conditions.heart_disease },
                    { label: 'cancer', image: assets.medical_conditions.cancer },
                    { label: 'lung_disease', image: assets.medical_conditions.lung_disease },
                    { label: 'thyroid_disease', image: assets.medical_conditions.thyroid_disease },
                    { label: 'gastric_disease', image: assets.medical_conditions.gastric_disease },
                ],
                choiceGroups: [
                    ['none'],
                    [
                        'hypertension',
                        'high_cholesterol',
                        'obesity',
                        'diabetes',
                        'heart_disease',
                        'cancer',
                        'lung_disease',
                        'thyroid_disease',
                        'gastric_disease',
                    ],
                ],
            },
            {
                name: 'personal_summary',
                type: 'custom',
                path: 'personal_summary',
                component: <PersonalSummary />,
            },
            {
                name: 'meal_prep',
                type: 'custom',
                path: 'meal_prep',
                component: <InterstitialStep animation={assets.animations.reonboarding_fasty2} label="meal_prep" />,
            },
            {
                name: 'meal_record',
                type: 'select',
                path: 'do_you_record_meals',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'every_meal', image: assets.meal_record.every_meal },
                    { label: 'some_meals', image: assets.meal_record.some_meals },
                    { label: 'no_meals', image: assets.meal_record.no_meals },
                ],
            },
            {
                name: 'nutrients_consumed',
                type: 'select',
                path: 'nutrients_consumed',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'knows_everything', image: assets.nutrients_consumed.knows_everything },
                    { label: 'need_to_check', image: assets.nutrients_consumed.need_to_check },
                    { label: 'not_really', image: assets.nutrients_consumed.not_really },
                ],
            },
            {
                name: 'food_scanner',
                type: 'custom',
                path: 'food_scanner',
                component: <LottieTextStep animation={assets.animations.food_scan} reverseOrder={true} />,
            },
            {
                name: 'struggle_eating',
                type: 'select',
                path: 'struggle_eating',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'always', image: assets.struggle_eating.always },
                    { label: 'sometimes', image: assets.struggle_eating.sometimes },
                    { label: 'rarely', image: assets.struggle_eating.rarely },
                ],
            },
            {
                name: 'food_scanner_intro_1',
                type: 'custom',
                path: 'barcode_scanning',
                component: <FoodScanTextStep />,
            },
            {
                name: 'food_scanner_intro_2',
                type: 'custom',
                path: 'food_scanning',
                component: <FoodScanTextStep />,
            },
            {
                name: 'food_scanner_intro_3',
                type: 'custom',
                path: 'menu_scanning',
                component: <FoodScanTextStep />,
            },
            {
                name: 'relationship_calories_weight',
                type: 'select',
                path: 'relationship_between_calories_and_weight',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'yes', image: assets.relationship_calories_weight.yes },
                    { label: 'somewhat', image: assets.relationship_calories_weight.somewhat },
                    { label: 'no', image: assets.relationship_calories_weight.no },
                ],
            },
            {
                name: 'technologies_used',
                type: 'custom',
                path: 'technologies_used',
                component: <TechnologiesUsedStep />,
            },
            {
                name: 'ai_tracker_success',
                type: 'custom',
                path: 'ai_tracker_success',
                component: <TrackerSuccessStep />,
            },
            {
                name: 'eating_habits_prep',
                type: 'custom',
                path: 'eating_habits_prep',
                component: (
                    <InterstitialStep animation={assets.animations.reonboarding_fasty2} label="eating_habits_prep" />
                ),
            },
            {
                name: 'eating_habits_v2',
                type: 'select',
                path: 'your_eating_habits',
                isRequired: true,
                multiple: true,
                imageOptions: [
                    { label: 'likes_chocolate', image: assets.eating_habits_v2.likes_chocolate },
                    { label: 'likes_soda', image: assets.eating_habits_v2.likes_soda },
                    { label: 'likes_salt', image: assets.eating_habits_v2.likes_salt },
                    { label: 'likes_midnight_snacks', image: assets.eating_habits_v2.likes_midnight_snacks },
                    { label: 'likes_fastfood', image: assets.eating_habits_v2.likes_fastfood },
                    { label: 'eats_when_feeling_down', image: assets.eating_habits_v2.eats_when_feeling_down },
                    { label: 'overeating', image: assets.eating_habits_v2.overeating },
                    { label: 'likes_drinking', image: assets.eating_habits_v2.likes_drinking },
                    { label: 'none', image: assets.eating_habits_v2.none },
                ],
                choiceGroups: [
                    ['none'],
                    ['likes_chocolate', 'likes_soda', 'likes_salt', 'likes_midnight_snacks', 'likes_fastfood'],
                    ['eats_when_feeling_down', 'overeating', 'likes_drinking'],
                ],
            },

            {
                name: 'snacking_urge',
                type: 'select',
                path: 'snacking_urge_trigger',
                isRequired: true,
                multiple: true,
                imageOptions: [
                    { label: 'food_around', image: assets.snacking_urge.food_around },
                    { label: 'boredom', image: assets.snacking_urge.boredom },
                    { label: 'hunger', image: assets.snacking_urge.hunger },
                    { label: 'other_people_snacking', image: assets.snacking_urge.other_people_snacking },
                    { label: 'something_else', image: assets.snacking_urge.something_else },
                ],
            },
            {
                name: 'comparison',
                type: 'custom',
                path: 'fastic_vs_diet',
                component: <ComparisonStep />,
            },
            {
                name: 'improving_eating_habits',
                type: 'select',
                path: 'improving_eating_habits',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'awareness', image: assets.improving_eating_habits.awareness },
                    { label: 'focus_on_whole_food', image: assets.improving_eating_habits.focus_on_whole_food },
                    { label: 'more_fruits_vegetables', image: assets.improving_eating_habits.more_fruits_vegetables },
                    { label: 'learning_about_nutrition', image: assets.snacking_urge.other_people_snacking },
                    { label: 'avoidance', image: assets.improving_eating_habits.avoidance },
                    { label: 'portion_control', image: assets.improving_eating_habits.portion_control },
                ],
            },
            {
                name: 'meals_per_day',
                type: 'select',
                path: 'meals_per_day',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                multiImageOptions: [
                    {
                        label: 'two',
                        images: [assets.meals_per_day.one, assets.meals_per_day.two],
                    },
                    {
                        label: 'three',
                        images: [assets.meals_per_day.one, assets.meals_per_day.two, assets.meals_per_day.three],
                    },
                    {
                        label: 'four',
                        images: [
                            assets.meals_per_day.one,
                            assets.meals_per_day.two,
                            assets.meals_per_day.three,
                            assets.meals_per_day.four,
                        ],
                    },
                    {
                        label: 'five',
                        images: [
                            assets.meals_per_day.one,
                            assets.meals_per_day.two,
                            assets.meals_per_day.three,
                            assets.meals_per_day.four,
                            assets.meals_per_day.five,
                        ],
                    },
                ],
            },
            {
                name: 'food_choice',
                type: 'custom',
                path: 'food_choice',
                component: <FoodChoiceStep />,
            },
            {
                name: 'calorie_cutting',
                type: 'custom',
                path: 'calorie_cutting',
                component: <CalorieCuttingStep />,
            },
            {
                name: 'water',
                type: 'select',
                path: 'water_intake',
                isRequired: true,
                hideButtonBar: true,
                completeOnSelect: true,
                multiImageOptions: [
                    {
                        label: 'two',
                        images: [assets.water.water_glass, assets.water.water_glass],
                    },
                    {
                        label: 'two_to_six',
                        images: [
                            assets.water.water_glass,
                            assets.water.water_glass,
                            assets.water.water_glass,
                            assets.water.water_glass,
                        ],
                    },
                    {
                        label: 'six_plus',
                        images: [
                            assets.water.water_glass,
                            assets.water.water_glass,
                            assets.water.water_glass,
                            assets.water.water_glass,
                            assets.water.water_glass,
                            assets.water.water_glass,
                        ],
                    },
                    {
                        label: 'other',
                        images: [assets.water.coffee, assets.water.tea, assets.water.soda],
                    },
                ],
            } as Question,
            /* Omit because of dropoff rate on this step
            {
                name: 'eating_window',
                type: 'custom',
                isRequired: true,
                path: 'eating_window',
                component: <EatingWindowStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    const eatingWindow = answers['eating_window']
                    if (Array.isArray(eatingWindow) && eatingWindow.length >= 2) {
                        const [start, end] = eatingWindow
                        return (
                            typeof start === 'string' &&
                            typeof end === 'string' &&
                            validateEatingWindow(start, end) === null
                        )
                    }
                    return false
                },
            },
            */
            {
                name: 'diet',
                type: 'select',
                path: 'any_specific_diet',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'no_diets', image: assets.diet_v2.no },
                    { label: 'keto', image: assets.diet_v2.keto },
                    { label: 'vegan', image: assets.diet_v2.vegan },
                    { label: 'vegetarian', image: assets.diet_v2.vegetarian },
                    { label: 'paleo', image: assets.diet_v2.paleo },
                    { label: 'climatarian', image: assets.diet_v2.climatarian },
                    { label: 'alkaline', image: assets.diet_v2.alkaline },
                    { label: 'pescatarian', image: assets.diet_v2.pescatarian },
                ],
            },
            {
                name: 'diet_restrictions',
                type: 'select',
                path: 'any_diet_restrictions',
                multiple: true,
                imageOptions: [
                    { label: 'none', image: assets.diet_restrictions.none },
                    { label: 'sugar_free', image: assets.diet_restrictions.sugar_free },
                    { label: 'lactose_free', image: assets.diet_restrictions.lactose_free },
                    { label: 'gluten_free', image: assets.diet_restrictions.gluten_free },
                    { label: 'nut_free', image: assets.diet_restrictions.nut_free },
                ],
                choiceGroups: [['none'], ['sugar_free', 'lactose_free', 'gluten_free', 'nut_free']],
            },
            {
                name: 'cooking_skills',
                type: 'select',
                path: 'cooking_skills',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'expert', image: assets.cooking_skills.expert },
                    { label: 'intermediate', image: assets.cooking_skills.intermediate },
                    { label: 'beginner', image: assets.cooking_skills.beginner },
                ],
            },
            {
                name: 'time_spend_cooking',
                type: 'select',
                path: 'time_spend_cooking',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'time_max_15m', image: assets.time_spend_cooking.time_max_15m },
                    { label: 'time_15_to_30m', image: assets.time_spend_cooking.time_15_to_30m },
                    { label: 'time_30_to_60m', image: assets.time_spend_cooking.time_30_to_60m },
                    { label: 'time_more_than_60m', image: assets.time_spend_cooking.time_more_than_60m },
                ],
            },
            {
                name: 'storytelling_meal',
                type: 'custom',
                path: 'storytelling_meal',
                component: <StorytellingMealStep />,
            },
            {
                name: 'nutritional_summary',
                type: 'custom',
                path: 'nutritional_summary',
                component: <NutrionalSummary />,
            },
            {
                name: 'find_out_motivation',
                type: 'custom',
                path: 'find_out_motivation',
                component: (
                    <InterstitialStep animation={assets.animations.reonboarding_fasty2} label="find_out_motivation" />
                ),
            },
            //WORKS but not when going back and changing answers!!!
            {
                name: 'your_motivation',
                type: 'select',
                path: 'your_motivation',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'change_look', image: assets.your_motivation.change_look },
                    { label: 'feel_better', image: assets.your_motivation.feel_better },
                    { label: 'improve_health', image: assets.your_motivation.improve_health },
                ],
            },
            {
                name: 'change_look',
                type: 'select',
                path: 'change_look',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'look_better_in_clothes', image: assets.change_look.look_better_in_clothes },
                    { label: 'change_measurements', image: assets.change_look.change_measurements },
                    { label: 'more_attractive', image: assets.change_look.more_attractive },
                    { label: 'more_satisfied', image: assets.change_look.more_satisfied },
                ],
                shouldRender: (currentAnswers: Answers) =>
                    currentAnswers.your_motivation && currentAnswers.your_motivation[0] === 'change_look',
            } as Question,
            {
                name: 'feel_better',
                type: 'select',
                path: 'feel_better',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'have_more_energy', image: assets.feel_better.have_more_energy },
                    { label: 'feel_better_in_clothes', image: assets.feel_better.feel_better_in_clothes },
                    { label: 'more_confident', image: assets.feel_better.more_confident },
                    { label: 'more_comfortable', image: assets.feel_better.more_comfortable },
                ],
                shouldRender: (currentAnswers: Answers) =>
                    currentAnswers.your_motivation && currentAnswers.your_motivation[0] === 'feel_better',
            } as Question,
            {
                name: 'improving_health',
                type: 'select',
                path: 'improving_health',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'better_general_health', image: assets.improving_health.better_general_health },
                    {
                        label: 'managing_existing_conditions',
                        image: assets.improving_health.managing_existing_conditions,
                    },
                    {
                        label: 'prevent_health_conditions',
                        image: assets.improving_health.prevent_health_conditions,
                    },
                    { label: 'boost_immunity', image: assets.improving_health.boost_immunity },
                ],
                shouldRender: (currentAnswers: Answers) =>
                    currentAnswers.your_motivation && currentAnswers.your_motivation[0] === 'improve_health',
            } as Question,
            {
                name: 'special_occasion',
                type: 'select',
                path: 'special_occasion',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'vacation', image: assets.special_occasion.vacation },
                    {
                        label: 'wedding',
                        image: assets.improving_health.managing_existing_conditions,
                    },
                    { label: 'birthday', image: assets.special_occasion.birthday },
                    { label: 'summer', image: assets.special_occasion.summer },
                    { label: 'school_reunion', image: assets.special_occasion.school_reunion },
                    { label: 'none', image: assets.special_occasion.none },
                ],
            },
            {
                name: 'special_occasion_date',
                type: 'custom',
                path: 'special_occasion_date',
                component: <SpecialOccasionDate />,
                shouldRender: (currentAnswers: Answers) =>
                    currentAnswers.special_occasion && currentAnswers.special_occasion[0] !== 'none',
            } as Question,
            {
                name: 'most_motivation',
                type: 'custom',
                path: 'what_motivates_you',
                component: <LottieTextStep animation={assets.animations.chart_reach_goal} />,
            },
            {
                name: 'happy_with_weight',
                type: 'select',
                path: 'happy_with_weight',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'last_year', image: assets.happy_with_weight.last_year },
                    {
                        label: 'one_to_two_years',
                        image: assets.happy_with_weight.one_to_two_years,
                    },
                    { label: 'over_three_years', image: assets.happy_with_weight.over_three_years },
                    { label: 'now', image: assets.happy_with_weight.now },
                    { label: 'never', image: assets.happy_with_weight.never },
                ],
            },
            {
                name: 'prior_weightloss_experience',
                type: 'select',
                path: 'prior_weightloss_experience',
                isRequired: true,
                completeOnSelect: true,
                imageOptions: [
                    { label: 'none_of_these', image: assets.prior_weightloss_experience.none_of_these },
                    { label: 'lack_motivation', image: assets.prior_weightloss_experience.lack_motivation },
                    {
                        label: 'weight_rebound',
                        image: assets.prior_weightloss_experience.weight_rebound,
                    },
                    { label: 'no_change', image: assets.prior_weightloss_experience.no_change },
                    { label: 'not_enough_time', image: assets.prior_weightloss_experience.not_enough_time },
                ],
            },
            {
                name: 'weight_loss_success',
                type: 'custom',
                path: 'weight_loss_success',
                component: <StorytellingWeightLoss />,
            },
            {
                name: 'statement_1',
                type: 'custom',
                hideButtonBar: true,
                path: 'weight_confidence',
                component: <StatementStep />,
            },
            {
                name: 'statement_2',
                type: 'custom',
                hideButtonBar: true,
                path: 'finding_right_plan',
                component: <StatementStep />,
            },
            {
                name: 'statement_3',
                type: 'custom',
                hideButtonBar: true,
                path: 'resistance_junk_food',
                component: <StatementStep />,
            },
            {
                name: 'statement_4',
                type: 'custom',
                hideButtonBar: true,
                path: 'indulgences_guilty_pleasure',
                component: <StatementStep />,
            },
            {
                name: 'reviews',
                type: 'custom',
                path: 'reviews',
                component: <Reviews />,
            },
            {
                name: 'shed_fat',
                type: 'custom',
                path: 'shed_fat',
                component: <ShedFatStep />,
            },
            {
                name: 'motivation_questions',
                type: 'custom',
                hideButtonBar: true,
                path: 'want_to_lose_weight',
                component: <MotivationQuestion />,
            },
            {
                name: 'prepare_plan',
                type: 'custom',
                path: 'preparing_your_plan',
                isRequired: false,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <PreparePlanStep />,
            },
            //Personal Health Plan: TO DO
            //REWORK DONE UNTIL HERE
            {
                name: 'program',
                type: 'custom',
                path: 'your_program',
                isRequired: true,
                hideHeader: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <PlanReadyStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: has purchased
                    return false
                },
            },
            {
                name: 'insurance_paywall',
                type: 'custom',
                path: 'your_weightloss_plan',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <InsurancePaywall />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
            },
            {
                name: 'registration_insurance',
                type: 'custom',
                path: 'registration_insurance',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                component: <SignUpInsurance />,
                shouldRender: (currentAnswers: Answers): boolean => currentAnswers.insurance_checkout === true,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'registration',
                type: 'custom',
                path: 'registration',
                isRequired: true,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <RegistrationStep />,
                validate: (question: Question, answers: Answers): boolean => {
                    // TODO: only if purchased
                    return false
                },
            },
            {
                name: 'download',
                type: 'custom',
                path: 'download',
                isRequired: false,
                hideStepper: true,
                hideButtonBar: true,
                hideBackHeader: true,
                component: <DownloadStep />,
            },
        ],
    }
}

export default getSurveyConfigV2
